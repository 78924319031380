import React from "react"

export default function Tc() {
  return (
    <div className="lg:w-3/4 w-full flex flex-col justify-center items-center text-black">
      <div className="w-full flex justify-center items-center">
        <h1>TERMS AND CONDITIONS</h1>
      </div>
      <div className="w-full flex flex-col justify-center items-center">
        <div className="legal-section">
          <h1>1. GENERAL CONDITIONS</h1>
          <div>
            <span>1.1 Scope and Definitions</span>
            <br />
            <span>
              1.1.1 The below mentioned General Conditions will apply to all the
              Subscribers/ Users/ Clients for the Services provided by M/s VSN
              Shared Services Private Limited (“VSN”)
            </span>
            <br />
            <span>1.2 Defined Terms</span>
            <br />
            <span>
              1.2.1 “Service” refers to the platform provided by VSN through its
              website, as a one stop solution to the day to day requirements of
              the Client/ Users in relation to accounting, book keeping, tax
              advisory and compliances, payroll processing, fund management,
              VCFO or part time CFO services, budgeting, compliance support.
            </span>
            <br />
            <span>
              1.2.2 “Subscribers/ Clients” refers to a person/ entity who shall
              register with VSN through the Website with an intention to avail
              the Services provided by VSN
            </span>
            <br />
            <span>
              1.2.3 “Website” means http://www.VSNsharedservices.com which is
              maintained or operated by VSN which offers similar services.
            </span>
            <br />
            <span>1.3 Acceptance of Conditions</span>
            <br />
            <span>
              1.3.1 The following conditions will be applicable to all the
              Subscribers/Clients to the website. The Subscribers/Clients
              signify their acceptance to these Conditions in full and agree to
              be bound by them. It is also agreed by the Subscribers/Clients
              that no access to the Services will be permitted unless the
              Conditions are accepted in full. No Subscriber/ Client is entitled
              to accept part of the Conditions. If a Subscriber does not agree
              to the Conditions then such Subscriber/ Client shall not use the
              Services which are being provided by VSN on its website. Also, in
              order to use the Services, Subscribers/Clients must be at least 18
              years old and able to form legally binding contracts under
              applicable law.
            </span>
            <br />
            <span>
              1.3.2 All Subscribers/Clients agree to comply with the Conditions
              and accept that any personal information that you provide to the
              company may be processed in accordance with the Privacy Policy. In
              the event that any Subscriber fails to comply with any of the
              Conditions at anytime during the tenure of their Service, VSN
              reserves the right to withdraw the Client’s Account in question
              and suspend or withdraw all Services pertaining to that
              Subscriber/ Client without any further notice.
            </span>
            <br />
            <span>1.4 Access and Interference</span>
            <br />
            <span>
              1.4.1 The Clients agree that they shall not use any robot, spider,
              other automatic device, or manual process to monitor or copy our
              Web pages or the content contained thereon or for any other
              unauthorized purpose without prior expressed written permission
              from VSN. The Clients agree that they shall not use any device,
              software, or routine to interfere or attempt to interfere with the
              proper working of the Site. The Clients agree that they shall not
              copy, reproduce, alter, modify, create derivative works from, or
              publicly display any content (except for your own personal,
              non-commercial use) from the Site without the prior expressed
              written permission of VSN.
            </span>
            <br />
          </div>
        </div>
        <div className="legal-section">
          <h1>2. USE OF THE SERVICE</h1>
          <div>
            <span>2.1 Client’s Account and Accuracy of Information</span>
            <br />
            <span>
              2.1.1 In order to use the Services, each Subscriber/ Client may
              provide its email address and agree to provide required
              information requested by VSN.
            </span>
            <br />
            <span>
              2.1.2 Subscribers/Clients agree and accept that all of the
              information provided by them to VSN while availing services or at
              any other time, shall be true, proper, correct, complete and
              accurate in all respects. Subscribers/Clients also agree and
              understand that VSN does not undertake any verification to confirm
              the accuracy of any information provided by the
              Subscribers/Clients on the Site. VSN will not be liable to any
              Subscriber/ Client in the event, any information including payment
              details provided by Subscriber is false, incomplete, inaccurate,
              misleading or fraudulent.
            </span>
            <br />
            <span>
              2.1.3 The respective Client is responsible for safeguarding the
              password and email address provided to VSN for accessing the
              Services. The respective Client is responsible for all activities
              that occur under their respective account, and the Client hereby
              undertakes that any person using the Services with Client’s email
              address and password is either himself/herself or is authorized to
              act on her/his behalf. The Client agrees to immediately notify VSN
              of any unauthorized use of the account.
            </span>
            <br />
            <span>2.2 Services and Terms of Use</span>
            <br />
            <span>
              2.2.1 VSN website helps its users/ Clients to gain a competitive
              advantage by providing them with the business contact information
              which caters to the business needs of the respective Client. VSN,
              endeavours that the Client/ user must get in touch with relevant
              people across different industries which shall foster their
              respective business needs, inter-alia with its databases stocked
              with B2B targeted lists, web-based search tools, appointment
              setting services.
            </span>
            <br />
            <span>
              2.2.2 Unless specifically authorized in advance and in writing by
              VSN, The Clients will not share, sell, transfer or otherwise make
              the Data available to any third person or entity and Clients will
              use their your best efforts to prevent the misuse or unauthorized
              use of the Data by any third person or entity.
            </span>
            <br />
            <span>
              2.2.3 VSN assumes no liability for non-conversion on any leads
              which are obtained by the Clients/ Users from its website. The
              payments would not be refunded in the event leads are not
              converted by the Clients/ Users. However, in the event, the emails
              obtained from VSN are bounced back then the Client may apply for
              refund for such leads.
            </span>
            <br />
            <span>2.2.4 The Clients and Users undertake the following:</span>
            <br />
            <span>
              a)That the Client will use the emails Data in accordance with all
              applicable Laws, including the CAN-SPAM Act, The EU General Data
              Protection Regulation (GDPR), the Telephone Consumer Protection
              Act, and any State Registry laws.
            </span>
            <br />
            <span>
              b) That the Client acknowledges that certain Data may include
              names and phone numbers that appear on one or more do-not-contact
              lists maintained by a federal, state, provincial, or other
              governmental entity or whose use may otherwise be regulated. The
              Clients are responsible for subscribing to all applicable
              do-not-contact lists and every Client further agrees to comply
              with all such relevant Laws, including those relating to any such
              do-not-contact lists.
            </span>
            <br />
            <span>
              c) The information obtained from the Website must be used for any
              purpose covered under the Fair Credit Reporting Act (15 U.S.C.
              §1681, et seq.).
            </span>
            <br />
            <span>
              d) The information obtained from the Website must not be used for
              transmitting any obscene commercial, FAKE advertising or
              promotional materials, including without limitation, “spam”.
            </span>
            <br />
            <span>
              e) The information obtained from the Website must not be used to
              harass, offend, threaten, embarrass, or invade the privacy of any
              individual or entity.
            </span>
            <br />
          </div>
        </div>
        <div className="legal-section">
          <h1>3. Payments and Refunds</h1>
          <div>
            <span>
              3.1 VSN accepts payments vide PayPal and via Wire transfers. The
              Clients/Users shall be responsible for all kinds of fee, duties
              and taxes. Further, the Client/ Users shall also be additionally
              charged for any subsequent increase in rate of duties, taxes and
              fees.
            </span>
            <br />
            <span>
              3.2 In the event email obtained from the website is bounced back
              then the Client is required to provide the requisite details and
              can have its refund within a reasonable period of time.
            </span>
            <br />
          </div>
        </div>
        <div className="legal-section">
          <h1>4. LIMITATION OF LIABILITY</h1>
          <div>
            <span>
              4.1 The information hosted on the website is provided on “as is”
              basis without any representations or warranties, express or
              implied by the VSN. Further, VSN makes no representations or
              warranties in relation to the services provided on the website. On
              becoming a Client with VSN i.e. accessing the website,
              Subscribers/Clients acknowledge and agree to waive any and all
              rights to, or claims for, damages (including special and
              consequential damages), costs, expenses, proprietary or any other
              compensation of any kind whatsoever against the VSN and VSN's
              partners, that arise directly or indirectly with respect to
              Subscribers/Clients use and/or reliance on the information
              including Email IDs provided on the Site.
            </span>
            <br />
            <span>
              4.2 VSN nor its affiliates, shall be liable to the
              Subscribers/Clients for any incidental, consequential, special,
              indirect or exemplary damages arising out of its information
              hosted on its website, including lost profits or costs of cover,
              loss of use or business interruption or the like, regardless of
              whether the party was advised of the possibility of such damages.
              Notwithstanding anything to the contrary contained herein, VSN
              shall have no monetary liability to any Subscriber for any cause
              (regardless of the form of action) under or relating to this
              agreement.
            </span>
            <br />
          </div>
        </div>
        <div className="legal-section">
          <h1>5. EXCLUSION OF WARRANTIES</h1>
          <div>
            <span>
              5.1 Subscribers/Clients acknowledge and agree that VSN will be
              using the information provided on the website at Subscriber’s own
              risk and that VSN and the affiliates are in no way responsible,
              and provide no warranty (express or implied) and / or guaranty of
              any kind with respect to, the information provided therein.
            </span>
            <br />
            <span>
              5.2 The services and products are provided on an "as is" and "with
              all faults" basis and VSN and its suppliers expressly disclaim all
              warranties, express or implied, including but not limited to, the
              implied warranties of non-infringement, merchantability,
              satisfactory quality, accuracy, title and fitness for a particular
              purpose. This disclaimer of warranties is an essential condition
              of the agreement.
            </span>
            <br />
          </div>
        </div>
        <div className="legal-section">
          <h1>6. GENERAL TERMS</h1>
          <div>
            <span>6.1 Intellectual Property</span>
            <br />
            <span>
              6.1.1 Subscribers/Clients acknowledge and agree that the statutory
              and other proprietary rights in respect of patents, designs,
              copyright, trademarks, trade secrets, processes, formulae,
              systems, drawings, data, specifications, documents, and other like
              rights relating to the Services or displayed or referred to on the
              VSN's website is owned by the Company or in some cases third
              parties. The Subscriber must not reproduce copy, transmit, adapt,
              publish or communicate or otherwise exercise the intellectual
              property rights in the whole or any part of the material contained
              on the VSN's Site except with the prior written consent of VSN.
            </span>
            <br />
            <span>6.2 Compliance with Law</span>
            <br />
            <span>
              6.2.1 VSN may disclose information that is necessary to comply
              with any applicable law, regulation, legal process or governmental
              request. In addition, VSN may disclose any information when it is
              necessary to prevent physical harm or financial loss or in
              connection with suspected or actual illegal activity.
            </span>
            <br />
            <span>6.3 Updates</span>
            <br />
            <span>
              6.3.1 VSN may update its Policies from time to time.. You are
              advised to review this Policy periodically for any changes.
              Changes to this Policy are effective when they are posted on this
              page.
            </span>
            <br />
            <span>6.4 FORCE MAJEURE</span>
            <br />
            <span>
              6.4.1 Subscribers/Clients agree and understand that VSN, VSN’s
              affiliates and Associates and Partner may not be able to perform
              their respective obligations in an event of Force Majeure and
              shall not be liable for any losses suffered by Subscribers/Clients
              in the event of Force Majeure.
            </span>
            <br />
          </div>
        </div>
        <div className="legal-section">
          <h1>7. ARBITRATION</h1>
          <div>
            <span>
              7.1 Subscribers/Clients/Users hereby agree that any and all
              disputes, claims, questions, or disagreements arising out of or in
              relation to these Terms and Conditions. The Parties shall
              endeavour to settle such disputes amicably through discussion
              within a period of 30 days from the date of notice by either party
              of the dispute. Failure to which shall lead to initiation of
              Arbitration proceedings as specified under the law of arbitration
              applicable in India, i.e. the Arbitration and Conciliation Act,
              1996 (as amended or substituted) from time to time.
            </span>
            <br />
            <span>
              7.2 Notice for the Arbitration shall be served within 30 days of
              failure of parties to settle the dispute amicably through
              discussion. The dispute(s) shall be referred to a Sole Arbitrator.
              The venue of Arbitration shall be New Delhi, India and the
              proceedings shall be conducted in English language. Further, the
              notice served on the email ID of the Subscriber(s) shall be deemed
              to be considered as valid service of the notice.
            </span>
            <br />
          </div>
        </div>
        <div className="legal-section">
          <h1>8. Governing Law and Jurisdiction</h1>
          <div>
            <span>
              8.1 This Agreement shall be governed by the laws of India in all
              material particular and each Party hereby submits to the exclusive
              jurisdiction of the Courts of Delhi.
            </span>
            <br />
          </div>
        </div>
        <div className="legal-section">
          <h1>9. Opt Out</h1>
          <div>
            <span>
              9.1 In the event, any user/ Client desires that the Email ID
              should be removed from the data base of VSN then the User/ Client
              is advised that he/ she must use the OPT OUT option available on
              the Website and request VSN to remove his/her email address from
              its server/ database.
            </span>
            <br />
          </div>
        </div>
        <div className="legal-section">
          <h1>10. Severability</h1>
          <div>
            <span>
              10.1 In the event that any provision of this T&C is declared by
              any judicial or other competent authority to be void, voidable,
              illegal or otherwise unenforceable or indications of the same are
              received by either of the Parties from any relevant competent
              authority, the Parties shall amend that provision in such
              reasonable manner that achieves the intention of the parties
              without illegality or it may be severed from this T&C and the
              remaining provisions of this T&C shall remain in full force and
              effect.
            </span>
            <br />
          </div>
        </div>
      </div>
    </div>
  )
}
