import React from "react"

import Seo from "../../components/seo"

import Tc from "../../components/Legal/Tc"

import MobileNavbar from "../../components/MobileNavbar"
import Navbar from "../../components/Navbar"
import Footer from "../../components/Footer"
import ContactFooter from "../../components/ContactFooter"
import Whatsapp from "../../components/Whatsapp"

export default function TcPage() {
  return (
    <>
      <Whatsapp />
      <Seo title="Terms & Conditions" />
      <div className="w-full flex flex-col justify-center items-center font-helvetica">
        <div className="md:hidden w-full flex flex-col justify-center items-center font-helvetica">
          <MobileNavbar>
            <Tc />
            <Footer />
            <ContactFooter />
          </MobileNavbar>
        </div>
        <div className="md:visible md:w-full md:flex md:flex-col md:justify-center md:items-center md:font-helvetica hidden">
          <Navbar />
          <Tc />
          <Footer />
          <ContactFooter />
        </div>
      </div>
    </>
  )
}
