import React from "react"

import { useStaticQuery, graphql } from "gatsby"

export default function Whatsapp({ fixed = true }) {
  const data = useStaticQuery(graphql`
    {
      whatsapp: allFile(
        filter: {
          relativeDirectory: { eq: "homePage" }
          name: { eq: "whatsapp" }
          extension: { eq: "svg" }
        }
      ) {
        edges {
          node {
            publicURL
          }
        }
      }
    }
  `)

  var { whatsapp } = data

  whatsapp = whatsapp.edges[0].node

  if (!fixed) {
    return (
      <div>
        <img
          src={whatsapp.publicURL}
          alt="whatsapp"
          className="hover:drop-shadow-xl"
          width={64}
        />
      </div>
    )
  } else {
    return (
      <div className="z-10 fixed bottom-0 right-0 lg:p-3">
        <a
          href="https://wa.me/919899783871?text=Hey, I wanna know about VSN"
          target="_blank"
          rel="noopener"
        >
          <img
            src={whatsapp.publicURL}
            alt="whatsapp"
            className="hover:drop-shadow-xl"
            width={80}
          />
        </a>
      </div>
    )
  }
}
